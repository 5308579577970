<template>
  <div class="wrapper wrapper-content animated fadeInRight page store schedule">
    <div class="ibox ">

      <div class="ibox-content">

        <div class="table-responsive">
          <table ref="list" class="table table-stripped toggle-arrow-tiny" data-paging="false">
            <thead>

              <tr class="fields">
                <th colspan="3">
                  <div class="form-group row"><label class="col-sm-2 col-form-label">Store ID</label>
                    <div class="col-sm-10">
                      <input class="form-control text" type="text" name="uid" readonly="true" :value="data.uid" />
                    </div>
                  </div>

                  <div class="form-group row"><label class="col-sm-2 col-form-label">Store Name</label>
                    <div class="col-sm-10">
                      <input class="form-control text" type="text" name="store_name" readonly="true" :value="data.store_name" />
                    </div>
                  </div>

                  <div class="form-group row right">
                    <div class="date-range-input">
                      <div class="input-group">
                        <div class="input">
                          <datetime v-model="date_start" type="date" zone="Asia/Seoul" name="date_start" :week-start="0" placeholder="예약불가 시작일" />
                          <div class="divine"></div>
                          <datetime v-model="date_end" type="date" zone="Asia/Seoul" name="date_end" :week-start="0" placeholder="예약불가 종료일" />
                        </div>
                        <span class="input-group-append">
                          <button type="button" class="btn btn-submit" @click="onSubmit">Save</button>
                        </span>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>

              <tr>
                <th colspan="3">

                  <div class="info">Showing {{page}} to {{Math.max(1, parseInt(total/size))}} of <strong>{{total}}</strong> entries</div>
                  <div class="padding"></div>

                  <!-- <SearchInput v-model="keyword" @submit="onSearch" /> -->
                </th>
              </tr>

              <tr class="fields">
                <th scope="col">#</th>
                <th scope="col">Closed Date</th>
                <th scope="col">Timestamp</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in items">
                <td>{{row.uid}}</td>
                <td>{{row.reserve_date_start}} ~ {{row.reserve_date_end}}</td>
                <td>{{row.updatedAt || row.createdAt}}</td>
                <td>
                  <button class="btn btn-xs btn-danger" @click="onClickDelete(row)">{{'삭제'}}</button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" class="text-left">
                  <Pagination :page="page" :size="size" :total="total" @page="onPage" />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/elements/Pagination"
import SearchInput from "@/components/utils/SearchInput"

export default {
  mounted() {
    this.$data.mode = this.$route.meta.mode
    this.$data.model = this.$route.meta.model

    this.loadData()
    this.loadList({})
  },
  data() {
    return {
      mode: 'unset',
      model: {},
      page: 1,
      size: 10,
      total: 0,
      items: [],
      data: {},
      date_start: '',
      date_end: '',
      keyword: ''
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(this.model.types.get.action, this.$route.params)
        .then((data) => {
          this.$data.data = data
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    loadList(options = {}) {
      let { page, size, keyword } = options
      this.$data.page = page || this.page
      this.$data.size = size || this.size
      this.$data.keyword = keyword || this.keyword

      this.$store.dispatch(this.model.types.schedule.list.action, { uid: this.$route.params.uid, page: this.$data.page, size: this.$data.size, filter: 'all', keyword: this.keyword })
        .then((data) => {
          let { items, total } = data

          this.$data.items = items
          this.$data.total = total
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onClear() {

    },
    onPage() {

    },
    onSubmit() {
      this.$store.dispatch(this.model.types.schedule.add.action, { uid: this.$route.params.uid, start_date: this.date_start + ' 00:00', end_date: this.date_end + ' 23:59' })
        .then((data) => {
          this.$toast.success("스케쥴이 추가되었습니다.")
          this.loadList({page: 1, keyword:''})
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

    onClickDelete(row) {
      this.$dialog
        .confirm(`${row.reserve_date_start} ~ ${row.reserve_date_end} 스케쥴을 삭제하겠습니까?`, {
          okText: 'Delete',
          cancelText: 'Cancel',
        })
        .then((dialog) => {
          this.onDelete(row)
        })
        .catch((e) => {
          this.$toast.error(e.message)
        })
    },

    onDelete(row) {
      if (!(this.model.types.schedule.delete && this.model.types.schedule.delete.action)) {
        this.$toast.error('Delete Action 이 정의되지 않았습니다.')
        return
      }

      this.$store.dispatch(this.model.types.schedule.delete.action, { uid: this.$route.params.uid, schedule_id: row.uid })
        .then((data) => {
          this.$toast.success("스케쥴이 삭제되었습니다.")
          this.loadList({page: 1, keyword:''})
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },

  },
  components: {
    Pagination, SearchInput
  }
}
</script>
